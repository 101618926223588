/*  feature item */
.feature-item {
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 30px 30px 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.icon-box {
  width: 125px;
  height: 125px;
  position: relative;
}
.icon-box .icon {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 23px;
  background-color: #6f64e7;
  color: #fff;
  text-align: center;
  line-height: 62px;
  position: absolute;
  left: 0;
  bottom: 16%;
  box-shadow: 0 3px 20px 0 rgba(111, 100, 231, 0.15);
  z-index: 1;
}
.icon-box img {
  position: absolute;
  top: 0;
  right: 0;
}
.feature-item h5 {
  margin-bottom: 10px;
}
.feature-item p {
  line-height: 2.1;
}
.feature-item:hover .icon {
  animation: bounce 1s;
}

.col-lg-4:nth-child(2) .icon-box .icon {
  background-color: #fbb019;
  box-shadow: 0 3px 20px 0 rgba(251, 176, 25, 0.15);
}
.col-lg-4:nth-child(3) .icon-box .icon {
  background-color: #fc60b2;
  box-shadow: 0 3px 20px 0 rgba(252, 96, 178, 0.15);
}
.col-lg-4:nth-child(5) .icon-box .icon {
  background-color: #fbb019;
  box-shadow: 0 3px 20px 0 rgba(251, 176, 25, 0.15);
}
.col-lg-4:nth-child(6) .icon-box .icon {
  background-color: #fc60b2;
  box-shadow: 0 3px 20px 0 rgba(252, 96, 178, 0.15);
}
.col-lg-4:nth-child(8) .icon-box .icon {
  background-color: #fbb019;
  box-shadow: 0 3px 20px 0 rgba(251, 176, 25, 0.15);
}
.col-lg-4:nth-child(9) .icon-box .icon {
  background-color: #fc60b2;
  box-shadow: 0 3px 20px 0 rgba(252, 96, 178, 0.15);
}

.feature-item.style2 {
  text-align: center;
  background-color: transparent;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0);
  padding: 15px;
}
.feature-item.style2 .icon {
  width: 60px;
  height: 60px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 23px;
  background-color: #6f64e7;
  color: #fff;
  text-align: center;
  line-height: 62px;
  box-shadow: 0 3px 20px 0 rgba(111, 100, 231, 0.15);
  z-index: 1;
  margin-bottom: 20px;
}
.col-md-6:nth-child(2) .feature-item.style2 .icon {
  background-color: #fbb019;
  box-shadow: 0 3px 20px 0 rgba(251, 176, 25, 0.15);
}
.col-md-6:nth-child(3) .feature-item.style2 .icon {
  background-color: #fc60b2;
  box-shadow: 0 3px 20px 0 rgba(252, 96, 178, 0.15);
}

.bg-ash-color2 {
  background-color: #faf9ff;
}
.shape-animation li {
  position: absolute;
}

.cross-shape.one {
  left: 5%;
  top: 30%;
  animation: round1 20s infinite;
}
.cross-shape.two {
  right: 10%;
  bottom: 7%;
  animation: round2 20s infinite;
}
.cross-shape span {
  position: relative;
  width: 24px;
  height: 24px;
  display: block;
  transform: rotate(-45deg) scale(0.8);
}
.cross-shape span:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 6px;
  margin-top: -3px;
  background-color: #aaa5f1;
  border-radius: 5px;
}
.cross-shape span:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
  background-color: #aaa5f1;
  border-radius: 5px;
}

.rect-shape.one {
  left: 48%;
  top: 20%;
  animation: round2 15s infinite;
}
.rect-shape.two {
  left: 53%;
  bottom: 15%;
  animation: round1 25s infinite;
}
.rect-shape.three {
  left: 7%;
  top: 55%;
  animation: round2 10s infinite;
}
.rect-shape.four {
  left: 3%;
  bottom: 2%;
  animation: round1 20s infinite;
}
.rect-shape span {
  width: 24px;
  height: 24px;
  border: 5px solid #aaa5f1;
  display: block;
  border-radius: 3px;
  transform: rotate(-45deg) scale(0.8);
}

.roundShapeleft {
  position: relative;
}
.roundShapeleft:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
  height: 150px;
  background-image: url(../images/roundShape1.png);
  background-size: cover;
}
@media (min-width: 992px) {
  .roundShapeleft:after {
    width: 300px;
    height: 300px;
  }
}

.roundShaperight {
  position: relative;
}
.roundShaperight:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 30%;
  width: 100px;
  height: 150px;
  background-image: url(../images/roundShape2.png);
  background-size: cover;
}
@media (min-width: 992px) {
  .roundShaperight:before {
    width: 226px;
    height: 300px;
  }
}

.angleShape {
  position: relative;
}
.angleShape:after {
  content: '';
  position: absolute;
  left: 48%;
  top: 50%;
  width: 1273px;
  height: 1000px;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  border-radius: 120px;
  background-color: #eef3fa;
  z-index: 0;
  transform: skew(-15deg) rotate(38deg) translateY(-50%);
}

@keyframes round1 {
  0%,
  100% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(480deg);
  }
}
@keyframes round2 {
  0%,
  100% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(-480deg);
  }
}
.contact-section .container.roundShape4 {
  position: relative;
}
.contact-section .container.roundShape4:after {
  content: '';
  position: absolute;
  left: -70px;
  top: 20px;
  width: 150px;
  height: 150px;
  background-image: url(../images/roundShape2.png);
  background-size: cover;
  z-index: -1;
}
@media (min-width: 992px) {
  .contact-section .container.roundShape4:after {
    left: -150px;
    top: -15px;
    width: 300px;
    height: 300px;
  }
}

.feature-item.style3 {
  text-align: left;
  padding: 50px 30px 30px 30px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-color: #f9fbfa;
  margin-bottom: 30px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
}
.feature-item.style3 p {
  margin-bottom: 7px;
}
.feature-item.style3 .icon {
  width: 60px;
  height: 60px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 23px;
  background-color: transparent;
  text-align: center;
  line-height: 62px;
  z-index: 1;
  margin-bottom: 20px;
}
.feature-item.style3:hover .icon {
  background-color: #01d85f;
  color: white;
  box-shadow: 0 3px 20px 0 rgba(1, 216, 95, 0.15);
}
.feature-item.style3 .read-more-btn {
  font-size: 20px;
  color: #01d85f;
  opacity: 0;
  transform: translateX(-30px);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.feature-item.style3:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 8px;
  background-color: #01d85f;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.active .feature-item.style3 {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}
.active .feature-item.style3 .icon {
  background-color: #01d85f;
  color: white;
  box-shadow: 0 3px 20px 0 rgba(1, 216, 95, 0.15);
}
.active .feature-item.style3 .read-more-btn {
  opacity: 1;
  transform: translateX(0px);
}
.active .feature-item.style3:after {
  width: 100%;
}

.feature-item.style3:hover {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}
.feature-item.style3:hover .icon {
  background-color: #01d85f;
  color: white;
  box-shadow: 0 3px 20px 0 rgba(1, 216, 95, 0.15);
}
.feature-item.style3:hover .read-more-btn {
  opacity: 1;
  transform: translateX(0px);
}
.feature-item.style3:hover:after {
  width: 100%;
}
